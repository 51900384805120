// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-audit-index-js": () => import("./../../../src/pages/audit/index.js" /* webpackChunkName: "component---src-pages-audit-index-js" */),
  "component---src-pages-cardano-list-index-js": () => import("./../../../src/pages/cardano-list/index.js" /* webpackChunkName: "component---src-pages-cardano-list-index-js" */),
  "component---src-pages-data-index-js": () => import("./../../../src/pages/data/index.js" /* webpackChunkName: "component---src-pages-data-index-js" */),
  "component---src-pages-graph-index-js": () => import("./../../../src/pages/graph/index.js" /* webpackChunkName: "component---src-pages-graph-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kickstart-index-js": () => import("./../../../src/pages/kickstart/index.js" /* webpackChunkName: "component---src-pages-kickstart-index-js" */),
  "component---src-pages-nft-index-js": () => import("./../../../src/pages/nft/index.js" /* webpackChunkName: "component---src-pages-nft-index-js" */),
  "component---src-pages-roadmap-index-js": () => import("./../../../src/pages/roadmap/index.js" /* webpackChunkName: "component---src-pages-roadmap-index-js" */),
  "component---src-pages-stake-calculator-js": () => import("./../../../src/pages/stake/calculator.js" /* webpackChunkName: "component---src-pages-stake-calculator-js" */),
  "component---src-pages-stake-index-js": () => import("./../../../src/pages/stake/index.js" /* webpackChunkName: "component---src-pages-stake-index-js" */),
  "component---src-pages-stake-pools-js": () => import("./../../../src/pages/stake/pools.js" /* webpackChunkName: "component---src-pages-stake-pools-js" */),
  "component---src-pages-swap-index-js": () => import("./../../../src/pages/swap/index.js" /* webpackChunkName: "component---src-pages-swap-index-js" */),
  "component---src-pages-tada-distribution-js": () => import("./../../../src/pages/tada/distribution.js" /* webpackChunkName: "component---src-pages-tada-distribution-js" */),
  "component---src-pages-tada-governance-js": () => import("./../../../src/pages/tada/governance.js" /* webpackChunkName: "component---src-pages-tada-governance-js" */),
  "component---src-pages-tada-index-js": () => import("./../../../src/pages/tada/index.js" /* webpackChunkName: "component---src-pages-tada-index-js" */),
  "component---src-pages-tada-tokenomics-js": () => import("./../../../src/pages/tada/tokenomics.js" /* webpackChunkName: "component---src-pages-tada-tokenomics-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */),
  "component---src-pages-wallet-index-js": () => import("./../../../src/pages/wallet/index.js" /* webpackChunkName: "component---src-pages-wallet-index-js" */),
  "component---src-pages-whitepaper-index-js": () => import("./../../../src/pages/whitepaper/index.js" /* webpackChunkName: "component---src-pages-whitepaper-index-js" */),
  "component---src-pages-wiki-brand-js": () => import("./../../../src/pages/wiki/brand.js" /* webpackChunkName: "component---src-pages-wiki-brand-js" */),
  "component---src-pages-wiki-cardano-js": () => import("./../../../src/pages/wiki/cardano.js" /* webpackChunkName: "component---src-pages-wiki-cardano-js" */),
  "component---src-pages-wiki-index-js": () => import("./../../../src/pages/wiki/index.js" /* webpackChunkName: "component---src-pages-wiki-index-js" */),
  "component---src-pages-wiki-liquidity-providers-js": () => import("./../../../src/pages/wiki/liquidity-providers.js" /* webpackChunkName: "component---src-pages-wiki-liquidity-providers-js" */),
  "component---src-pages-wiki-stake-delegators-js": () => import("./../../../src/pages/wiki/stake-delegators.js" /* webpackChunkName: "component---src-pages-wiki-stake-delegators-js" */),
  "component---src-pages-wiki-tadatek-js": () => import("./../../../src/pages/wiki/tadatek.js" /* webpackChunkName: "component---src-pages-wiki-tadatek-js" */),
  "component---src-pages-xdiamond-index-js": () => import("./../../../src/pages/xdiamond/index.js" /* webpackChunkName: "component---src-pages-xdiamond-index-js" */)
}

